$theme-colors: (
        "primary": #d5ae5c,
        "secondary": #8F8F8F,
        "sucess": #4e8e25,
        "danger": #c43329,
        //"warning": ,
        "info": #0687f5,
        "light": #EBEEEF,
        "dark": #202E32
);
@import "~@coreui/coreui-pro/scss/_variables.scss";
/************* Theme colors ***************/
/*
$theme-colors: (
        "primary": #408EA3,
        "secondary": #8F8F8F,
        "sucess": #4e8e25,
        "danger": #c43329,
        //"warning": ,
        "info": #0687f5,
        "light": #EBEEEF,
        "dark": #202E32
);

$gray-100: #f9f9f9;
$gray-200: #e6e6e6;
$gray-300: #C8C8C8;
$gray-400: #ACACAC;
$gray-500: #999999;
$gray-600: #8F8F8F;
$gray-700: #727272;
$gray-800: #393939;
$gray-900: #1D1D1D;
*/
// Aside

$aside-section-menu-width:                    350px !default;
$aside-section-menu-color:                    $gray-800 !default;
$aside-section-menu-bg:                       #fff !default;
$aside-section-menu-borders: (
  left: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$aside-section-menu-nav-padding-y:            .75rem !default;
$aside-section-menu-nav-padding-x:            1rem !default;

$aside-section-menu-footer:             100px !default;

//Settings Asidebar vars
$aside-settings-menu-width:                    350px !default;
$aside-settings-menu-color:                    $gray-800 !default;
$aside-settings-menu-bg:                       #fff !default;
$aside-settings-menu-borders: (
  left: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
) !default;

$aside-settings-menu-nav-padding-y:            .75rem !default;
$aside-settings-menu-nav-padding-x:            1rem !default;

$aside-settings-menu-footer:             100px !default;
